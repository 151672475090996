<template>
  <div class="rego-type mw-960">
    <!-- <h3 class="mt-2">Returning Participant</h3>
    <LongLink text="Re-register" @go="choose('reregister')"/> -->
    <h3 class="mt-1">Get Involved</h3>
    <LongLink id="playerRegister" text="Player" @go="choose('player')"/>
    <LongLink text="Coach, trainer or volunteer" @go="choose('coach')"/>
    <LongLink text="Referee" @go="choose('referee')"/>
    <LongLink text="Register a Team" @go="choose('team')"/>
  </div>
</template>

<script>
import LongLink from '@/components/LongLink.vue';

export default {
  name: 'regotype',
  props: ['source'],
  components: {
    LongLink,
  },
  methods: {

    choose(type) {
      if (type === 'team') {
        this.$store.dispatch('registrationTeams/updateCurrent', { type });

        this.$router.push({ name: 'compsearch' });
      } else { // include type in `current rego`
        this.$store.dispatch('registration/updateCurrent', { type });

        // then go to the route
        // it's all club search, but map state there

        this.$router.push({ name: 'clubsearch' });
      }
    },
  },
  mounted() {
    if (this.source) {
      // 'rugby-league' or 'touch-football' and commits corresponding national org to store
      this.$store.dispatch('views/updateNationalOrg', this.source);
    }
    this.$store.dispatch('registrationTeams/clearCurrent');
    this.$store.dispatch('registration/clearCurrent');
  },
};
</script>

<style lang="scss" scoped>
h3 {
  @media( max-width: $sm) {
    margin-bottom: 0.5rem;
  }
}
.mw-960 {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}
</style>
